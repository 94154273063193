import React from 'react';
import { Button } from './Button';
import type { ButtonProps } from './Button';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface DropdownProps extends ButtonProps {
	options: { label: string; onClick: () => void; disabled: boolean }[];
}

function Dropdown(props: DropdownProps) {
	const { options, children, className, ...buttonProps } = props;
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);
	function onClick(callback: () => void) {
		callback();
		setIsOpen(false);
	}
	return (
		<div className="dropdown">
			<Button
				className={classNames('btn-secondary dropdown-toggle d-flex', className, isOpen && 'show')}
				type="button"
				data-bs-toggle="dropdown"
				{...buttonProps}
				aria-expanded={isOpen}
				onClick={() => setIsOpen(v => !v)}
			>
				{children}
			</Button>
			<ul className={classNames('dropdown-menu dropdown-menu-end py-0 mt-1', isOpen && 'show')} style={{ width: 'auto', minWidth: '100%' }}>
				{options.map((option) => (
					<li key={option.label.replace(' ', '')}>
						{option.disabled && (
							<Tooltip id={`tooltip-${option.label}`} place="right" />
						)}
						<span
							data-tooltip-id={option.disabled ? `tooltip-${option.label}` : undefined}
							data-tooltip-content={t('courses.item_already_added',{item: option.label})}
						>
							<button className="dropdown-item" onClick={() => onClick(option.onClick)} disabled={option.disabled}>{option.label}</button>
						</span>
					</li>
				))}
			</ul>
		</div>
	);
}
export default Dropdown;