import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthorizationWrapper from 'auth/AuthorizationWrapper';
import { Permissions } from 'auth/Permissions';
import classNames from 'classnames';
import { Button } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
interface IProps {
	title: string;
	showMenuAdmin: boolean;
	setShowMenuAdmin: (v: boolean) => void;
}

const AdminMenuItems = (props: IProps) => {
	const { t } = useTranslation();
	const handleMenuClick = () => {
		props.setShowMenuAdmin(!props.showMenuAdmin);
	};
	const menu = (
		<ul className={classNames('dropdown-menu', props.showMenuAdmin && 'show')}>
			<AuthorizationWrapper type="hide" permissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
				<li>
					<NavLink className="dropdown-item" to="/admin/users" onClick={() => props.setShowMenuAdmin(false)}>
						<span>Users</span>
					</NavLink>
				</li>
			</AuthorizationWrapper>
			<AuthorizationWrapper type="hide" permissions={[Permissions.ReadRole, Permissions.WriteRole]}>
				<li>
					<NavLink className="dropdown-item" to="/admin/roles" onClick={() => props.setShowMenuAdmin(false)}>
						<span>Roles</span>
					</NavLink>
				</li>
			</AuthorizationWrapper>
			<AuthorizationWrapper type="hide" permissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
				<li>
					<NavLink className="dropdown-item" to="/pick-lists" onClick={() => props.setShowMenuAdmin(false)}>
						<span>Pick lists</span>
					</NavLink>
				</li>
			</AuthorizationWrapper>
		</ul>
	);

	return (
		<li className="nav-item">
			<Button
				onClick={() => handleMenuClick()}
				className={
					classNames(
						'btn-ghost-primary nav-link px-3 py-2',
						(location.pathname.startsWith('/schools') || location.pathname.startsWith('/courses') || location.pathname.startsWith('/networks')) && 'not-active')}
			>
				{t('navbar.admin_tools')}
			</Button>
			{menu}
		</li>
	);

};

export default AdminMenuItems;