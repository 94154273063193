import React from 'react';
import { FormGroup, Input, Button, DataTable, ActionIcon } from '@punchcard/core';
import { IconDelete, IconUpload } from '@punchcard/core/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RowInfo } from 'react-table';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { PickListContext } from 'context/PickListContext';
import { PickListSelect } from 'components/core/PicklistSelect';

interface CourseResourcesProps {
	courseId: number;
}

const CourseResources = (CourseResourcesProps: CourseResourcesProps) => {
	const { t } = useTranslation();
	const fileInputRef = React.useRef(null);
	const { courseId } = CourseResourcesProps;
	const methods = useFormContext<AdminCourseDTO>();
	const { control } = methods;
	const PickListCxt = React.useContext(PickListContext);
	const { append: appendCourseDocument,
		fields: fieldsCourseDocument,
		remove: removeCourseDocument,
	} = useFieldArray({ control, name: 'courseDocuments', keyName: 'key' });

	const deleteCourseDocument = async (row: RowInfo) => {
		const confirmed = window.confirm(t('documents.are_you_sure_you_want_to_delete_this_document'));
		if (confirmed) {
			const result = await adminAPI.course.deleteCourseDocument(row.original.id);
			if (result === true) {
				removeCourseDocument(row.index);
			}
		}
	};
	const getPickListCaption = (code?: string) => {
		if (code) {
			const pickList = PickListCxt.pickListItems.find(p => p.code === code);
			return pickList?.value1;
		}
	};
	const uploadCoursePdf = async (file: FileList | null) => {
		if (file && file.length > 0) {
			if (file[0].type !== 'application/pdf') {
				toast.error(t('courses.invalid_file_format'));
				return;
			}
			const doc: AdminCourseDocumentDTO = {
				id: 0,
				courseId: courseId,
				documentName: file[0].name,
				versionNumber: 1,
				documentTypeCode: '',
				courseUnitId: undefined,
				courseLessonId: undefined,
				courseActivityId: undefined,
				courseAssessmentId: undefined,
				documentThumbnail: '',
			};
			const result = await adminAPI.course.uploadCourseDocument(file, doc);
			if (result) {
				appendCourseDocument(result);
			}
		}
	};
	const columns = [
		{
			Header: 'CourseDocument ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('courses.course_files'),
			accessor: 'documentName',
		},
		{
			accessor: 'DocumentTypeCode',
			Cell: (rowInfo: RowInfo) =>
				<Controller
					control={control}
					name={`courseDocuments.${rowInfo.index}.documentTypeCode`}
					render={({ field }) =>
						<React.Fragment>
							<PickListSelect
								picklistTypeCode="DocumentType"
								value={field.value}
								onChange={(newValue) => field.onChange((newValue as { value: string })?.value)}
								menuPortalTarget={document.body} // Render dropdown outside of parent
							/>
							<div className="fst-italic small">{getPickListCaption(field.value)}</div>
						</React.Fragment>
					}
				/>
			,
			Filter: <React.Fragment />,
			Header: 'Type',
			id: 'DocumentTypeCode',
			width: 300,
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title="Delete" icon={IconDelete} onClick={() => { deleteCourseDocument(rowInfo); }} />
				</React.Fragment>,
			Filter: <React.Fragment />,
			Header: '',
			id: 'Actions',
			width: 70,
		},

	];
	const uploadDocumentClick = () => {
		if (fileInputRef.current) {
			(fileInputRef.current as HTMLInputElement).click();
		}
	};
	return (
		<React.Fragment>

			<DataTable
				data={fieldsCourseDocument}
				columns={columns}
				resizable={false}
				sortable={false}
				filterable={false}
				showPagination={false}
				noDataText={t('documents.no_documents_found')}
				//loading={loading}
				className="z-0 first-column-data-table"
			/>
			<FormGroup>
				<Button className="btn-outline-primary" onClick={uploadDocumentClick} icon={<IconUpload />}>{t('documents.upload_pdf')}</Button>
				<Input ref={fileInputRef} type="file" className="d-none" accept="application/pdf" onChange={(e) => uploadCoursePdf(e.target.files)} />
			</FormGroup>
		</React.Fragment>
	);
};
export default CourseResources;
