import React from 'react';
import { Page, PageHero } from '@punchcard/core';
import { useTranslation } from 'react-i18next';


const LandingPage = () => {

	const { t } = useTranslation();

	return (
		<Page className="bg-light">
			<PageHero title={t('welcome_lyla')} />
		</Page >
	);
};

export default LandingPage;
