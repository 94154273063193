import React, { useContext } from 'react';
import { ErrorPage, LoadingScreen } from '@punchcard/core';
import FourOhThree from '@punchcard/core/assets/403.png';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import authAPI from 'api/authApi';
import { SettingsContext } from 'context/SettingsContext';
import { useTranslation } from 'react-i18next';

type IAuthContext = {
	currentUser: CurrentUserDTO,
};

const AuthProvider = (props: React.PropsWithChildren) => {
	const [currentUser, setCurrentUser] = React.useState({} as CurrentUserDTO);
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(true);
	const { accounts, instance } = useMsal();
	const { settings } = useContext(SettingsContext);

	React.useEffect(() => {
		// interceptor to get new token on a 401, then retry the request.
		axios.interceptors.response.use(
			response => response,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			async (error: any) => {
				const originalConfig = error.config;
				if (error.response) {
					if (error.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true; // don't retry, avoid infinite loop.
						const token = await getToken(true);
						originalConfig.headers.Authorization = `Bearer ${token}`;
						return axios.request(originalConfig);
					}
					else {
						return Promise.reject(error);
					}
				}
				else {
					console.error(error);
					return error;
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [axios]);

	React.useEffect(() => {
		const init = async () => {
			try {
				if (accounts.length > 0) {
					await getToken();
					await refreshUser();
				}
			} catch (error) {
				console.error('Error init token', error.message);
			} finally {
				setLoading(false);
			}
		};

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, instance, settings?.b2CApiScopes]);

	const refreshUser = async () => {
		const user = await authAPI.getCurrentUser();
		setCurrentUser(user);
		return true;
	};

	const getToken = async (forceRefresh: boolean = false) => {
		await instance.initialize();
		const activeAccount = instance.getActiveAccount() ?? accounts[0];
		const token = await instance.acquireTokenSilent({ scopes: settings.b2CApiScopes, account: activeAccount, forceRefresh: forceRefresh });
		axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
		return token.accessToken;
	};


	const CheckUserType = (userType: UserType, children: React.ReactNode) => {
		if (userType !== 'Admin') {
			return <ErrorPage
				numberError="403"
				imageSrc={FourOhThree}
				message={t('error.message403')}
				description={t('error.description403')}
				buttonMessage={t('error.back_to_home_page')}
				buttonAction={() => instance.logoutRedirect()}
			/>;
		} else {
			return children;
		}
	};

	return (
		<AuthContext.Provider value={{
			currentUser,
		}}>
			{loading ? <LoadingScreen /> :
				CheckUserType(currentUser.userType, props.children)
			}
		</AuthContext.Provider>
	);
};


export default AuthProvider;
export const AuthContext = React.createContext({} as IAuthContext);
