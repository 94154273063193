/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { ReactSketchCanvas, ReactSketchCanvasRef, CanvasPath as SketchCanvasPath, ReactSketchCanvasProps } from 'react-sketch-canvas';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Button, PDFReaderButtons, } from '@punchcard/core';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import GradeModal from './GradeModal';
import TextAreaContainer from './TextAreaContainer';



pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString();

const resizeObserverOptions = {};
const HALF_TEXT_AREA_HEIGHT = 24;
const maxWidth = 800;
interface IProps {
	pdfData: File;
	documentLoadSuccess: () => void;
	submitDrawing?: () => void;
	handleCanvasChange?: (path: CanvasPath, index: number) => void;
	isTeacherView?: boolean;
	currentActivity?: currentActivityAssessment;
	setCurrentActivity: (activity: currentActivityAssessment) => void;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange?: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	skipActivity?: (courseActivityId: number) => void;
	isActivityOpen: boolean;
	setIsDirty?: (isDirty: boolean) => void;

}


const PDFReader = (props: IProps) => {
	const { t } = useTranslation();
	const containerRef = React.useRef<HTMLElement | null>(null);
	const [numPages, setNumPages] = React.useState<number>();
	const [containerWidth, setContainerWidth] = React.useState<number>();
	const pageWidth = containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth;
	const [activeTextBox, setActiveTextBox] = React.useState<{ page: number, id: string } | null>(null);
	const activeTextBoxRef = React.useRef<{ page: number, id: string } | null>(null);
	const [gradeModalOpen, setGradeModalOpen] = React.useState<boolean>(false);
	const pageNumberArray = parseNumberSeries(props.currentActivity?.item?.pageNumbers);
	const methods = useFormContext<WIPForm>();
	const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
	const componentRef = React.useRef<HTMLDivElement>(null);
	const [mode, setUpdateMode] = React.useState<'pencil' | 'eraser' | 'type' | 'hand'>('pencil');
	const colour = '#004959';
	const size = 1;
	const eraserSize = 10;

	React.useEffect(() => {
		setTimeout(() => {
			if (activeTextBox && textAreaRef.current) {
				textAreaRef.current.focus();
			}
		}, 50);
	}, [activeTextBox]);

	React.useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			const target = event.target as HTMLElement;
			if (target.closest('.modal.show')) {
				return; // Exit if clicked inside a visible modal
			}
			if (
				componentRef.current &&
				!componentRef.current.contains(event.target as Node)
			) {
				setActiveTextBox(null);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setActiveTextBox]);

	React.useEffect(() => {
		activeTextBoxRef.current = activeTextBox;
	}, [activeTextBox]);

	const { control } = methods;
	const onResize = React.useCallback<ResizeObserverCallback>((entries) => {
		const [entry] = entries;
		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	React.useEffect(() => {
		if (mode !== 'type') {
			setActiveTextBox(null);
		}
	}, [mode]);


	const saveDrawing = (submit: boolean) => {
		if (props.currentActivity) {
			const updatedActivity = {
				...props.currentActivity,
				item: {
					...props.currentActivity.item,
					activityStatus: {
						...props.currentActivity?.item?.activityStatus,
						statusCode: submit ? 'ReadyForReview' : 'InProgress',
					},
				},
			};
			props.setCurrentActivity(updatedActivity);
		}
		props.submitDrawing && props.submitDrawing();
	};

	useResizeObserver(containerRef.current, resizeObserverOptions, onResize);

	const options = useMemo(() => {
		return {
			cMapUrl: '/cmaps/',
			standardFontDataUrl: '/standard_fonts/',
		};
	}, []);
	const addHistory = (index: number) => {
		if (mode === 'pencil' && props.isActivityOpen) {
			const { setValue, getValues } = methods;
			const { undoHistory, undoIndex } = getValues();
			if (undoIndex !== null) {
				const removeOldHistory = undoHistory.slice(undoIndex);
				setValue('undoHistory', [index, ...removeOldHistory]); // tihs is in reverse on purpose.
				setValue('undoIndex', null);
			} else {
				setValue('undoHistory', [index, ...undoHistory]); // tihs is in reverse on purpose.
			}
			if (props.setIsDirty) {
				props.setIsDirty(true);
			}
		}
	};
	const touchStart = React.useRef({ x: 0, y: 0 });

	// Pointer down to capture the start point
	const handlePointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
		//check if current target is an anchor
		if (event.target instanceof HTMLAnchorElement) {
			return;
		}
		if (mode === 'hand') {
			touchStart.current = { x: event.clientX, y: event.clientY };
			event.currentTarget.classList.add('is-scrolling');
			event.currentTarget.classList.add('cursor-grabbing');
			event.currentTarget.classList.remove('cursor-grab');
		}
	};
	const handlePointerUp = (event: React.PointerEvent<HTMLDivElement>) => {
		if (mode === 'hand') {
			event.currentTarget.classList.remove('is-scrolling');
			event.currentTarget.classList.add('cursor-grab');
			event.currentTarget.classList.remove('cursor-grabbing');
		}
	};


	// Pointer move to scroll based on the delta of the touch movement
	const handlePointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
		if (mode === 'hand' && event.currentTarget.classList.contains('is-scrolling')) {
			const deltaX = event.clientX - touchStart.current.x;
			const deltaY = event.clientY - touchStart.current.y;

			// Reset touchStart to current touch point to create a smooth scroll effect
			touchStart.current = { x: event.clientX, y: event.clientY };

			// Adjust the scroll position of the PDF container
			const pdfContainer = document.getElementById('pdf-container'); // Adjust this selector as needed
			if (pdfContainer) {
				pdfContainer.scrollLeft -= deltaX;
				pdfContainer.scrollTop -= deltaY;
			}
		}
	};

	return (
		<FormProvider {...methods}>
			<PDFReaderButtons
				updateMode={setUpdateMode}
				mode={mode}
				isTeacherView={props.isTeacherView}
				currentActivity={props.currentActivity}
				statusCodeMappingActivity={props.statusCodeMappingActivity}
				onStatusChange={props.onStatusChange}
				setGradeModalOpen={setGradeModalOpen}
				gradeModalOpen={gradeModalOpen}
			/>
			<div
				style={{ position: 'relative' }}
				className="d-flex flex-column flex-grow-1 overflow-y-auto"
			>
				{gradeModalOpen &&
					<GradeModal
						setShowGradeModal={setGradeModalOpen}
						currentActivity={props.currentActivity}
						onStatusChange={props.onStatusChange}
					/>}
				<div id="pdf-container" className={classNames('flex-column flex-grow-1 overflow-y-auto')}>
					<Document file={props.pdfData} onLoadSuccess={onDocumentLoadSuccess} options={options}>
						{Array.from(new Array(numPages), (_el, index) => (
							pageNumberArray && pageNumberArray.includes(index + 1) &&
							<Controller
								name={`studentLayer.${index}`}
								control={control}
								key={`studentlayer_${index}`}
								render={({ field }) => {
									return (
										// eslint-disable-next-line jsx-a11y/no-static-element-interactions
										<div
											key={`page_${index + 1}`}
											className={classNames('position-relative h-100 w-100', mode === 'hand' ? 'cursor-grab' : 'prevent-select')}
											onPointerDown={handlePointerDown}
											onPointerMove={handlePointerMove}
											onMouseDown={handlePointerDown}
											onPointerUp={handlePointerUp}
											onPointerCancel={handlePointerUp}
											onPointerLeave={handlePointerUp}
											onClick={(e) => {
												if (mode === 'type' && e.detail === 2) {
													const { clientX, clientY } = e;
													const { left, top } = e.currentTarget.getBoundingClientRect();
													const x = clientX - left;
													const y = clientY - top - HALF_TEXT_AREA_HEIGHT;
													const uuid = crypto.randomUUID();
													const pdf = document.querySelector('.react-pdf__Document');
													const pdfWidth = (pdf?.getBoundingClientRect().width || 400) - 10;
													setActiveTextBox({ page: index, id: uuid });
													field.onChange(
														{
															...field.value,
															typing: [
																...field.value?.typing || [],
																{
																	id: uuid,
																	x,
																	y,
																	width: pdfWidth - x,
																	content: '',
																},
															],
														},
													);
												}
											}}
										>
											<Page
												key={`page_${index + 1}`}
												width={pageWidth}
												pageNumber={index + 1}
											/>
											<div className="position-absolute top-0 left-0 w-100 h-100 right-0 bottom-0 divPage">
												<div className="d-flex flex-fill position-relative">
													{field.value?.typing?.map((text) => {
														return (
															<TextAreaContainer
																key={text.id}
																text={text}
																pageIndex={index}
																mode={mode}
																field={field}
																firstPage={index + 1 === pageNumberArray[0]}
																lastPage={index + 1 === pageNumberArray[pageNumberArray.length - 1]}
																activeTextBox={activeTextBox}
																setActiveTextBox={setActiveTextBox}
															/>
														);
													})}
												</div>
											</div>
											<Canvas
												className="position-absolute top-0 left-0 w-100 h-100 right-0 bottom-0"
												width="100%"
												id={`canvas-${index}`}
												height="100%"
												value={field.value?.paths as SketchCanvasPath[] | undefined}
												allowOnlyPointerType="all"
												strokeWidth={size}
												// transparent stroke color for teacher view or when submitted so no changes are made to student layer
												strokeColor={colour}
												isTeacherView={props.isTeacherView}
												statusCode={props.currentActivity?.item?.activityStatus?.statusCode}
												isActivityOpen={props.isActivityOpen}
												canvasColor="transparent"
												eraserWidth={eraserSize}
												mode={mode}
												index={index}
												onChange={(v) => {
													field.onChange({ ...field.value, paths: v });
													addHistory(index);

												}}
												style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}
											/>
										</div>
									);
								}} />
						))}
					</Document>
					{props.currentActivity && props.isActivityOpen && !props.isTeacherView &&
						<div className="p-3">
							<h5 className="pb-2">{props.currentActivity?.type === 'Activity' ? t('wip.is_your_work_complete') : t('wip.is_your_assessment_complete')}</h5>
							<div className="d-flex justify-content-between flex-fill">
								<div className="d-flex align-items-center  w-100">
									{props.currentActivity?.type === 'Activity' &&
										<Button className="btn-outline-primary me-2" onClick={() => props.currentActivity && props.skipActivity && props.skipActivity(props.currentActivity?.item?.id)} >{t('wip.skip')}</Button>
									}
									<Button className="btn-primary" onClick={() => saveDrawing(true)} >
										{props.currentActivity?.type === 'Activity' ? t('wip.submit_next_activty') : t('yes')}
									</Button>
								</div>
							</div>
						</div>
					}

				</div>

			</div>
		</FormProvider>
	);

	function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
		setNumPages(nextNumPages);
		props.documentLoadSuccess && props.documentLoadSuccess();
	}
};

interface CanvasProps extends ReactSketchCanvasProps {
	mode?: 'pencil' | 'eraser' | 'type' | 'hand';
	index: number;
	isLoading?: boolean;
	isTeacherView?: boolean;
	statusCode?: string;
	isActivityOpen?: boolean;
}
const CanvasComponent = (props: CanvasProps) => {
	const { mode, index, ...otherProps } = props;
	const ref = React.useRef<ReactSketchCanvasRef>(null);
	const { watch, getValues } = useFormContext<WIPForm>();
	const [undoIndex, lastAction] = watch(['undoIndex', 'lastAction']);

	React.useEffect(() => {
		if (mode) {
			ref.current?.eraseMode(mode === 'eraser');
		}
	}, [mode]);

	React.useEffect(() => {
		const { undoHistory } = getValues();
		if (undoHistory) {
			if (undoIndex !== null && (undoHistory[undoIndex] === index) && lastAction === 'undo') {
				ref.current?.undo();
			}
			if (undoIndex !== null && (undoHistory[undoIndex + 1] === index) && lastAction === 'redo') {
				ref.current?.redo();
			}
		}

	}, [undoIndex, lastAction, getValues, index]);

	return (
		<ReactSketchCanvas
			ref={ref}
			readOnly={props.isLoading || props.readOnly || props.mode === 'type' || props.mode === 'hand' || !props.isActivityOpen || props.statusCode === 'ReadyForReview' || props.isTeacherView}
			{...otherProps}
		/>
	);
};
const Canvas = React.memo(CanvasComponent, (prev, next) => {
	//return prev.defaultValue === next.defaultValue && next.isLoading === true;
	return next.isLoading === true;
});

function parseNumberSeries(input: string | undefined): number[] {
	if (input) {
		const segments = input.split(',');
		const result: number[] = [];
		for (const segment of segments) {
			if (segment.includes('-')) {
				const [start, end] = segment.split('-').map(Number);
				if (start <= end) {
					for (let i = start; i <= end; i++) {
						result.push(i);
					}
				} else {
					throw new Error(`Invalid range: ${segment}`);
				}
			} else {
				result.push(Number(segment));
			}
		}
		return result;
	}
	return [];
}

export default PDFReader;
