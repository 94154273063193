import React from 'react';
import { Button, FormGroup, TextArea } from '@punchcard/core';
import { IconClose, IconTask } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

interface CoursePbaProps {
	pbaIndex: number;
	removePba: (index: number) => void;
	name: string;
}

function CoursePba(CoursePbaProps: CoursePbaProps) {
	const { pbaIndex, removePba, name } = CoursePbaProps;
	const { t } = useTranslation();
	const { control } = useFormContext();

	return (
		<div className="px-4 py-2 mb-3 rounded-3 bg-quaternary-200">
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h6 className="mb-0 d-flex align-items-center">
					<IconTask className="me-1 icon-27" />
					{pbaIndex + 1}: {t('courses.project_based_assessment')}
				</h6>
				<Button
					className="btn-ghost-primary me-n3 btn-sm"
					onClick={() => removePba(pbaIndex)}
					icon={<IconClose className="icon-22" />}
				>
					{t('courses.remove')}
				</Button>
			</div>
			<Controller
				control={control}
				name={`${name}.${pbaIndex}.assessmentType`}
				render={({ field }) => {
					const assessmentLabels = {
						debate: 'Debate Artificial Intelligence',
						teach: 'Teach the teacher',
						art: 'Art Project',
						exam: 'Exam'
					};

					const assessmentText = (field.value as (keyof typeof assessmentLabels)[])
						.filter((type) => type !== 'exam')
						.map((type) => assessmentLabels[type])
						.join('\n');

					return (
						<FormGroup className="pb-2" label={t('courses.assessment_types')}>
							<TextArea
								{...field}
								rows={3}
								disabled={true}
								value={assessmentText}
							/>
						</FormGroup>
					);
				}}
			/>
		</div>
	);
}

export default CoursePba;