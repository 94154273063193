import React from 'react';
import { ActionIcon, Button, FormGroup, Input, TextArea } from '@punchcard/core';
import { IconClose, IconDelete, IconTask } from '@punchcard/core/icons';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AnswerKey from './AnswerKey';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
interface CourseExamProps {
	examIndex: number;
	removeExam: (index: number) => void;
	name: string;
	courseId: number;
	unitId: number;
}

const CourseExam = (CourseExamComponentProps: CourseExamProps) => {
	const { t } = useTranslation();
	const { control, setValue } = useFormContext();
	const { examIndex, removeExam, name, courseId, unitId } = CourseExamComponentProps;
	const assessmentId: number = useWatch({ control, name: `${name}.${examIndex}.Id` });
	const uploadExamPdf = async (files: FileList | null) => {
		if (files && files.length > 0 && courseId !== 0 && unitId !== 0) {
			if (files[0].type !== 'application/pdf') {
				toast.error(t('courses.invalid_file_format'));
				return;
			}
			const doc: AdminCourseDocumentDTO = {
				id: 0,
				courseId: courseId ?? 0,
				documentName: files[0].name,
				versionNumber: 1,
				documentTypeCode: '',
				courseUnitId: unitId,
				courseLessonId: undefined,
				courseActivityId: undefined,
				courseAssessmentId: assessmentId,
				documentThumbnail: '',
			};
			const result = await adminAPI.course.uploadCourseDocument(files, doc);
			return result;
		}
	};
	const deleteCourseDocument = async (documentId: number) => {
		const confirmed = window.confirm(t('documents.are_you_sure_you_want_to_delete_this_document'));
		if (confirmed) {
			const res = await adminAPI.course.deleteCourseDocument(documentId.toString());
			if (res === true) {
				setValue(`${name}.${examIndex}.courseDocuments`, []);
			}
			return res;
		} else {
			return false;
		}
	};

	return (
		<div className="px-4 py-2 mb-3 rounded-3 bg-quaternary-200">
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h6 className="mb-0 d-flex align-items-center">
					<IconTask className="me-1 icon-27" />
					{examIndex + 1}: {t('courses.exam')}
				</h6>
				<Button
					className="btn-ghost-primary me-n3 btn-sm"
					onClick={() => removeExam(examIndex)}
					icon={<IconClose className="icon-22" />}
				>
					{t('courses.remove')}
				</Button>
			</div>
			<Controller
				control={control}
				name={`${name}.${examIndex}.assessmentName`}
				defaultValue=""
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.assessment_name')} required={true}>
						<Input
							{...field}
							placeholder={t('courses.exam_name')}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${examIndex}.version`}
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.exam_version')}>
						<Input
							{...field}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${examIndex}.instructions`}
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.exam_instructions')}>
						<TextArea
							{...field}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${examIndex}.courseDocuments`}
				render={({ field }) => {
					return (
						<FormGroup label={t('courses.exam_pdf')}>
							{field.value?.[0]?.documentName ? (
								<div className="d-flex justify-content-between align-items-center">
									<p className="mb-0">{field.value?.[0]?.documentName}</p>
									<ActionIcon
										title="Delete"
										icon={IconDelete}
										onClick={async () => {
											// TODO: Implement deleteCourseDocument
											await deleteCourseDocument(field.value?.[0]?.id);
										}}
									/>
								</div>
							) : (
								<Input
									type="file"
									id="inputGroupFile04"
									accept="application/pdf"
									aria-describedby="inputGroupFileAddon04"
									aria-label="Upload"
									{...field}
									onChange={async (e) => {
										// TODO: Implement uploadUnitPdf
										const res = await uploadExamPdf(e.target.files);
										field.onChange(res);
									}}
								/>
							)}
						</FormGroup>
					);
				}}
			/>
			<AnswerKey examIndex={examIndex} name={`${name}.${examIndex}.courseAssessmentQuestions`} />
		</div>
	);
};
export default CourseExam;




