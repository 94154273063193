import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Page, FormGroup, Input, RadioGroup, LoadingScreen, Select } from '@punchcard/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { PickListSelect } from 'components/core/PicklistSelect';
import EntityHeader from '@punchcard/core/components/EntityHeader';


const SchoolPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [networks, setNetworks] = React.useState<{ value: string, label: string }[]>([]);
	const networkId: number = parseInt(params.networkId ?? '0');
	const methods = useForm<AdminSchoolDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			schoolName: '',
			schoolTimeZone: '',
			tenantId: networkId,
			isActive: true,
		}
	});
	const schoolId: number = parseInt(params.schoolId ?? '0');
	const pageTitle = schoolId === 0 ? t('schools.add_school') : t('schools.edit_school');
	const { handleSubmit, control, reset, formState } = methods;
	React.useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (schoolId !== undefined) {
				const school = await adminAPI.school.getSchool(schoolId);
				setNetworks(school.networkList.map((item) => {
					return { value: (item.id ?? 0).toString(), label: item.name };
				}));
				if (schoolId === 0 && networkId !== 0) {
					school.tenantId = networkId;
				}
				reset(school);
			}
			setLoading(false);
		}
		fetchData();
	}, [reset, schoolId]);

	const deleteSchool = async () => {
		const confirmed = window.confirm(t('schools.are_you_sure_you_want_to_delete_this_school'));
		if (confirmed) {
			const result = await adminAPI.school.deleteSchool(schoolId);
			if (result === true) {
				toast.success(t('schools.school_deleted'));
			} else {
				toast.error(t('schools.unable_to_delete_school'));
			}

			params.networkId ? navigate(`/networks/${params.networkId}/schools`) : navigate('/schools');
		}
	};
	const getOptionValue = (option: { value: string, label: string }) => {
		if (option === undefined) {
			return undefined;
		}
		const { value } = option as { value: string, label: string };
		return value;
	};
	const submitData = async (data: AdminSchoolDTO) => {
		setLoading(true);
		const school = await adminAPI.school.postSchool(data);
		if (school) {
			toast.success(t('schools.school_saved'));
			navigate(-1);
		} else {
			toast.error(t('schools.unable_to_save_school'));
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page className="bg-light p-3 ps-4" title={pageTitle} >
			<FormProvider {...methods}>
				<div className="container-fluid">
					<div className="row">
						<div className=" col-lg-6">
							<EntityHeader
								title={pageTitle}
								parentRoute={params.networkId ? `/networks/${params.networkId}/schools${location.search}` : `/schools${location.search}`}
								deleteEntity={schoolId !== 0 ? deleteSchool : undefined}
								saveEntity={handleSubmit(submitData)}
								isDirty={formState.isDirty}
							/>
							<div className="bg-white mt-3 p-3 rounded-2">
								<Controller
									control={control}
									name="tenantId"
									rules={{
										required: t('this_field_is_required'),
									}}
									render={({ field, fieldState }) =>
										<FormGroup label={t('networks.network_name')} fieldState={fieldState} className="pb-2" required={true}>
											<p className={'mb-2 mt-1'}>{t('schools.network_warning')}</p>
											<Select
												options={networks}
												isDisabled={schoolId !== 0}
												isMulti={false}
												value={field.value.toString()}
												onChange={field.onChange}
											/>
										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="schoolName"
									rules={{
										required: t('this_field_is_required'),
									}}
									render={({ field, fieldState }) =>
										<FormGroup label={t('schools.school_name')} fieldState={fieldState} className="pb-2" required={true}>
											<Input
												{...field}
											/>
										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="schoolTimeZone"
									rules={{
										required: t('this_field_is_required'),
									}}
									render={({ field, fieldState }) =>
										<FormGroup label={t('schools.school_timezone')} fieldState={fieldState} className="pb-2" required={true}>
											<PickListSelect
												picklistTypeCode="timezone"
												isMulti={false}
												value={field.value}
												onChange={(selected) => field.onChange(getOptionValue(selected as { value: string, label: string }))}
											/>
										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="isActive"
									render={({ field }) =>
										<FormGroup label={t('schools.school_status')} className="pb-2" required={true}>
											<RadioGroup
												options={[
													{ label: 'Active', value: true },
													{ label: 'In Active', value: false }
												]}
												value={field.value ?? true}
												onChange={field.onChange}
											/>
										</FormGroup>
									}
								/>

							</div>
						</div>
					</div>
				</div>
			</FormProvider>
		</Page >
	);
};

export default SchoolPage;
