import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

// Default screens
import { LoadingPage, LoadingScreen } from '@punchcard/core';
import FourOhFourPage from './screens/404Page';
import FourOhOnePage from './screens/401Page';
import LandingPage from './screens/LandingPage';

// Admin Pages
import TenantListPage from './admin/Tenant/TenantListingPage';
import TenantDetailsPage from './admin/Tenant/TenantDetailsPage';
import RoleListingPage from './admin/Authorization/RoleListingPage';
import RoleDetailsPage from './admin/Authorization/RoleDetailsPage';
import PickListTypeListPage from './admin/PickList/PickListTypeListPage';
import PickListItemListPage from './admin/PickList/PickListItemListPage';
import PickListTypeDetails from './admin/PickList/PickListTypeDetails';
import PickListItemDetails from './admin/PickList/PickListItemDetails';

// User Settings Pages
import UpdateUserInfo from './screens/UpdateUserInfo';
import UserProfile from './screens/UserProfile';
import UpdateUserPassword from './screens/UpdateUserPassword';

// Feedback Pages
import FeedbackList from './screens/feedback/FeedbackList';
import FeedbackDetails from './screens/feedback/FeedbackDetails';
import FeedbackForm from './screens/feedback/FeedbackForm';

// App History Tracking
import { EventEmitter } from 'events';
import AppHistoryTrackerProvider from './screens/feedback/useAppHistoryTracker';
import TrackerWrapper from 'context/useTracking';

// Authorization Imports
import { Permissions } from './auth/Permissions';
import RequiresPermissions from './context/RequiresPermissions';

import NavBar from 'components/navigation/NavBar';
import { SettingsContext } from 'context/SettingsContext';
import CoursePage from 'screens/Course/CoursePage';
import CoursesPage from 'screens/Course/CourseListPage';
import { ToastContainer } from 'react-toastify';
import StylingTest from 'screens/StylingTest';
import SchoolListPage from 'screens/School/SchoolListPage';
import SchoolPage from 'screens/School/SchoolPage';
import SchoolUserListPage from 'screens/School/SchoolUserListPage';
import SchoolUserPage from 'screens/School/SchoolUserPage';
import { useTranslation } from 'react-i18next';
import UsersList from 'screens/users/UsersList';
import UsersPage from 'screens/users/UsersPage';
import CourseSchoolsListPage from 'screens/Course/CourseSchoolsListPage';

// School Site Pages
import SchoolSiteList from 'screens/School/Site/SchoolSitesList';
import SchoolSite from 'screens/School/Site/SchoolSite';
import SchoolSiteUsersList from 'screens/School/Site/SchoolSiteUsersList';

export const eventEmitter = new EventEmitter();
import { AuthContext } from 'context/AuthContext';
import TermsAndConditions from '@punchcard/core/components/Shared/TermsAndConditions';
import EducatorList from 'screens/users/EducatorList';

const AppNavigator = () => {
	const { settings } = React.useContext(SettingsContext);
	const { t } = useTranslation();
	const { currentUser } = React.useContext(AuthContext);
	if (!currentUser?.agreedToTermsDate) {
		return <TermsAndConditions />;
	}
	const Root = () => (
		<AppHistoryTrackerProvider>
			<TrackerWrapper>
				<React.Fragment>
					<NavBar />
					<Outlet /> {/* This is where the nested routes will render */}
					<ToastContainer />
				</React.Fragment>
			</TrackerWrapper>
		</AppHistoryTrackerProvider>
	);

	const router = createBrowserRouter([
		{
			element: <Root />, // This is the root element that will be rendered
			errorElement: <FourOhFourPage />,
			children: [
				{
					path: '/',
					element: <LandingPage />,
				},
				{
					path: '401',
					element: <FourOhOnePage />,
				},
				{
					path: 'AzureADB2C/Account/SignOut',
					element: <LoadingScreen message={t('loading')} />,
				},
				{
					path: 'courses',
					element: <CoursesPage />,
				},
				{
					path: 'courses/:courseId',
					element: <CoursePage />,
				},
				{
					path: 'courses/:courseId/schools',
					element: <CourseSchoolsListPage />,
				},

				{
					path: 'networks',
					children: [
						{
							path: '/networks',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
									<TenantListPage />
								</RequiresPermissions>
							),
						},
						{
							path: '/networks/:networkId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
									<TenantDetailsPage />
								</RequiresPermissions>
							),
						},
						{
							path: '/networks/:networkId/schools',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolListPage />
								</RequiresPermissions>
							),
						},
						{
							path: '/networks/:networkId/educators',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser]}>
									<EducatorList />
								</RequiresPermissions>
							),
						},
						{
							path: '/networks/:networkId/schools/:schoolId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolPage />
								</RequiresPermissions>
							),
						},
						{
							path: '/networks/:networkId/schools/:schoolId/users', // Relative path for users within a specific school
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolUserListPage />,
								</RequiresPermissions>
							)
						},
						{
							path: '/networks/:networkId/schools/:schoolId/users/:userId', // Dynamic route for specific user within a school
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolUserPage />,
								</RequiresPermissions>
							)
						},
						{
							path: '/networks/:networkId/schools/:schoolId/sites',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolSiteList />
								</RequiresPermissions>
							)
						},
						{
							path: '/networks/:networkId/schools/:schoolId/sites/:siteId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolSite />,
								</RequiresPermissions>
							)
						},
						{
							path: '/networks/:networkId/schools/:schoolId/sites/:siteId/users',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<SchoolSiteUsersList />,
								</RequiresPermissions>
							)
						},
					],
				},
				{
					path: 'admin',
					children: [
						{
							path: 'roles',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<RoleListingPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'roles/:roleId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<RoleDetailsPage />
								</RequiresPermissions>
							),
						},

						{
							path: 'users',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<UsersList />
								</RequiresPermissions>
							),
						},
						{
							path: 'users/:userId/:networkId?',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<UsersPage />
								</RequiresPermissions>
							),
						},
					],
				},
				{
					path: 'schools', // Parent route for school-related pages
					children: [
						{
							path: '/schools', // Default school page (e.g., school listing)
							element: <SchoolListPage />,
						},
						{
							path: ':schoolId', // Dynamic route for specific school ID
							element: <SchoolPage />, // Component to render specific school details
						},
						{
							path: '/schools/:schoolId/users', // Relative path for users within a specific school
							element: <SchoolUserListPage />, // Component to render specific school user list
						},
						{
							path: '/schools/:schoolId/users/:userId', // Dynamic route for specific user within a school
							element: <SchoolUserPage />, // Component to render specific user details
						},
						{
							path: '/schools/:schoolId/sites',
							element: <SchoolSiteList />,
						},
						{
							path: '/schools/:schoolId/sites/:siteId',
							element: <SchoolSite />,
						},
						{
							path: '/schools/:schoolId/sites/:siteId/users',
							element: <SchoolSiteUsersList />,
						},
					]
				},
				{
					path: 'pick-lists',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListTypeListPage />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListTypeDetails />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId/pick-list-items',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListItemListPage />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId/pick-list-items/:pickListItemId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListItemDetails />
						</RequiresPermissions>
					),
				},
				{
					path: 'settings',
					children: [
						{
							path: 'profile',
							element: <UserProfile />,
						},
						{
							path: 'update-info',
							element: <UpdateUserInfo />,
						},
						{
							path: 'update-password',
							element: settings?.allowUserPasswordChange ? <UpdateUserPassword /> : <FourOhFourPage />,
						},
					],
				},
				{
					path: 'DesignSystem', //need to remove this
					element: <StylingTest />,
				},
				{
					path: 'feedback',
					element: <FeedbackForm />,
				},
				{
					path: 'feedback/feedbackList',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole]}>
							<FeedbackList />
						</RequiresPermissions>
					),
				},
				{
					path: 'feedback/:feedbackId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole]}>
							<FeedbackDetails />
						</RequiresPermissions>
					),
				},
			],
		},
	]);

	return <RouterProvider router={router} fallbackElement={<LoadingPage />} />;
};

export default AppNavigator;