
import { PickListContext } from 'context/PickListContext';

import React from 'react';
import { ActionMeta, default as ReactSelect, GroupBase, OptionsOrGroups, Props as ReactSelectProps } from 'react-select';


interface IProps extends Omit<ReactSelectProps, 'options' | 'filterOption'> {
	picklistTypeCode: string;
	error?: boolean;
	// Use the filterOptions if you need to filter items out of the picklist type that you don't need in your options
	filterOptions?: string[];
}
export const PickListSelect = (props: IProps) => {
	const { picklistTypeCode, value, isMulti, error, onChange, filterOptions, ...otherProps } = props;
	const PickListCxt = React.useContext(PickListContext);
	const options = getPicklistOptions(picklistTypeCode);
	return (
		<ReactSelect
			isMulti={isMulti || false}
			options={options}
			value={getValue(value, options)}
			onChange={onChangeVal}
			styles={{
				control: (
					styles: any,
					{ isFocused }) => ({ ...styles, borderRadius: '.5rem', borderColor: error ? '#F15D1E' : '#BDB3AD', boxShadow: error && isFocused ? '0 0 0 0.2rem #FBD6CC' : isFocused ? '0 0 0 0.2rem #F8EDCC' : 'none' }

				),
				option: (styles: any, { isFocused, isSelected, isDisabled }) => ({
					...styles,
					backgroundColor: isSelected ? '#E9CA66' : isFocused ? '#F8EDCC' : '#FFFFFF',
					fontWeight: isSelected ? 'bold' : 'normal',
					color: '#1E1D1D',
					':active': {
						...styles[':active'],
						backgroundColor: !isDisabled
							? isSelected
								? '#E9CA66'
								: '#F1DC99'
							: undefined,
						fontWeight: 'bold',
					},
				}),
			}}
			{...otherProps}
		/>
	);

	function getPicklistOptions(code: string) {
		const pickListType = PickListCxt.pickListTypes.filter(x => x.code === code);
		if (pickListType.length === 0) {
			return undefined;
		}
		const picklistOptions = PickListCxt.pickListItems.filter(x => x.pickListTypeId === pickListType[0].id);
		const options = picklistOptions.map((item) => {
			return { value: item.code, label: item.description, caption: item.value1 };
		});
		if (filterOptions !== undefined) {
			return options.filter(v => v.value && !filterOptions.includes(v.value));
		}
		return options;
	}

	function getValue(value: unknown, options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined) {
		if (Array.isArray(value)) {
			const val = options?.filter((v: any) => value.findIndex((j: any) => j === v.value) !== -1);
			return val;
		}
		if (typeof value === 'string') {
			const val = options?.filter((v: any) => v.value === value);

			return val;
		}
		return undefined;
	}

	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map(v => v.value);
			onChange(value, actionMeta);
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any; caption: any; };
			const value = thewNewValue!;
			onChange(value, actionMeta);
			return;
		}
		onChange(undefined, actionMeta);
		return undefined;
	}
};