
import React from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import DataTable from './DataTable';
import { useTranslation } from 'react-i18next';
import { IconArrowDown, IconArrowUp } from '@punchcard/core/icons';
import { Button } from './Button';
import { RowInfo } from 'react-table';

interface IRoleForm {
	id: number;
	name: string;
	description: string;
	permissionsGroup: string[];
	roles: RoleDTO[];
}

interface CustomRoleHierarchyComponentProps {
	field: ControllerRenderProps<IRoleForm, 'roles'>
	fieldState: { error?: FieldError };
	currentRole: IRoleForm;
}


const RoleHierarchy = (props: CustomRoleHierarchyComponentProps) => {
	const { field, currentRole } = props;
	const { value, onChange } = field;
	const { t } = useTranslation();
	const increment = () => {
		swapRoleHierarchy(currentRole.id, true);
	};
	const decrement = () => {
		swapRoleHierarchy(currentRole.id, false);
	};
	const swapRoleHierarchy = (selectedRoleId: number, increment: boolean) => {
		// Find the index of the selected role
		const selectedIndex = value.findIndex(role => role.id === selectedRoleId);
		if (increment === true) {
			if (selectedIndex > 0) {
				const updatedRoles = [...value];
				const currentRole = updatedRoles[selectedIndex];
				const previousRole = updatedRoles[selectedIndex - 1];
				const tempHierarchy = currentRole.roleHierarchy;
				currentRole.roleHierarchy = previousRole.roleHierarchy;
				previousRole.roleHierarchy = tempHierarchy;
				const sortedRoles = updatedRoles.sort((a, b) => {
					const hierarchyA = a.roleHierarchy ?? Infinity;
					const hierarchyB = b.roleHierarchy ?? Infinity;
					return hierarchyB - hierarchyA;
				});
				onChange(sortedRoles);
			}
		} else {
			if (selectedIndex < value.length - 1) {
				const updatedRoles = [...value];
				const currentRole = updatedRoles[selectedIndex];
				const nextRole = updatedRoles[selectedIndex + 1];
				const tempHierarchy = currentRole.roleHierarchy;
				currentRole.roleHierarchy = nextRole.roleHierarchy;
				nextRole.roleHierarchy = tempHierarchy;
				const sortedRoles = updatedRoles.sort((a, b) => {
					const hierarchyA = a.roleHierarchy ?? Infinity;
					const hierarchyB = b.roleHierarchy ?? Infinity;
					return hierarchyB - hierarchyA;
				});
				onChange(sortedRoles);
			}
		}
	};
	const columns = [
		{
			Header: 'ID',
			accessor: 'id',
			show: false,
		},
		{
			Header: 'hierarchy',
			accessor: 'roleHierarchy',
			show: false,
		},
		{
			Header: t('roles.role_name'),
			accessor: 'name',

			Cell: (rowValue: RowInfo) => (
				<span className={rowValue.original.id === currentRole.id || rowValue.original.value === currentRole.id ? 'fw-bold' : ''}>
					{rowValue.original.name}
				</span>
			)
		},
		{
			Header: '',
			accessor: 'roleHierarchy',
			width: 335,
			Cell: (rowValue: RowInfo) => (
				rowValue.original.id === currentRole.id ? (
					<React.Fragment>
						<Button
							className="p-1 pe-2 ps-2 btn btn-outline-primary rounded-1"
							onClick={increment}
						>
							<IconArrowUp /> {t(['roles.role_hierarchy_up'])}
						</Button>
						<Button
							className="p-1 ms-2  pe-2 ps-2 btn btn-outline-primary rounded-1"
							onClick={decrement}
						>
							<IconArrowDown /> {t(['roles.role_hierarchy_down'])}
						</Button>
					</React.Fragment>
				) : null

			)
		},

	];




	return (
		<React.Fragment>
			<style>
				{`
				.ReactTable .rt-thead .rt-th:not(:last-child) div:first-child::after, .ReactTable .rt-thead .rt-td:not(:last-child) div:first-child::after {
					background-image: none !important;
				}
				`}
			</style>
			<p className="mb-0">{t('roles.role_hierarchy_description')}</p>
			<DataTable
				data={value}
				columns={columns}
				filterable={false}
				sortable={false}
				showPagination={false}
				hasActions={false}
				noDataText={t('roles.no_roles_found')}
			/>
		</React.Fragment>
	);
};

export default RoleHierarchy;
